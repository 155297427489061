<script setup>
import { useUserStore } from '~/stores/user.store';

const userStore = useUserStore();

const user = computed(() => userStore.userGetter());

const form = ref({
  email: undefined,
  old_email: user.value?.email,
});

const submit = async () => {
  const res = await userStore.changeEmail(form.value);
  if (res.status !== 200) return;
  await userStore.getUserData();
  form.value = {
    email: undefined,
    old_email: user.value?.email,
  }
}
</script>
<template>
  <NuxtLayout name="mobile-base">

    <template #mobile-header>
      <mobile-header :title="$t('client.change_email')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
      </mobile-header>
    </template>

    <template v-if="user">
      <div class="mt-3 px-4">
        <WInput type="text" name="old_email" :placeholder="$t('client.old_email')" v-model:value="form.old_email"
          :disabled="true" />
      </div>
      <div class="mt-3 px-4">
        <WInput type="text" name="email" :placeholder="$t('client.email')" v-model:value="form.email" />
      </div>
      <div class="mt-6 px-4 flex items-center gap-3">
        <button @click="submit"
          class="bg-indigo-900 text-white rounded-lg p-3 capitalize  w-full text-center">Update</button>
      </div>
    </template>
    <template v-else>
      <div class="relative h-screen">
        <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <span class="loader"></span>
        </div>
      </div>
    </template>
    <template #mobile-footer></template>
  </NuxtLayout>
</template>